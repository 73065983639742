import React, { useState } from 'react';
import '../assets/css/Portfolio.css'; 


const Portfolio = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleImageClick = (project) => {
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  return (
    <div>
      <div className="grid">
        {projects.map((project, index) => (
          <div key={index} className="card">
            <img
              src={project.image}
              alt={project.title}
              className="image"
              onClick={() => handleImageClick(project)}
            />
            <h3>{project.title}</h3>
          </div>
        ))}
      </div>

      {selectedProject && (
        <div className="modalOverlay" onClick={handleCloseModal}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <button className="closeButton" onClick={handleCloseModal}>
              &times;
            </button>
            {/* Full-width header section */}
            <div className="modalHeader">
              <h2>{selectedProject.description.Title}</h2>
              <p>{selectedProject.description.Description}</p>
            </div>
            {/* Split layout for image, key features, website link, and target audience */}
            <div className="modalFlexContainer">
              {/* Image Section */}
              <div className="modalImageContainer">
                <img
                  src={selectedProject.image}
                  alt={selectedProject.title}
                  className="modalImage"
                />
                {/* Website Link and Target Audience Section below image */}
                <div className="websiteAndTargetContainer">
                  <div className="websiteLink">
                    <a href={selectedProject.website} target="_blank" rel="noopener noreferrer">
                      Visit Website
                    </a>
                  </div>

                  <div className="targetAudience">
                    <h4>Target Audience:</h4>
                    <ul>
                      {selectedProject.description.targetAudience.map((audience, i) => (
                        <li key={i}>{audience}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Key Features Section */}
              <div className="modalText">
                <h4>Key Features:</h4>
                <ul>
                  {selectedProject.description.KeyFeatures.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Portfolio;
