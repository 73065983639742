import React from 'react';
import PropTypes from 'prop-types';

const ServiceCard = ({ icon, title, description, buttonText }) => {
  return (
    <div className="card text-center h-100 shadow-lg  mb-5 bg-light rounded">
      <div className="card-body">
        <div className="icon mb-3">
          <i className={`${icon} fa-3x`}></i>
        </div>
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        {/* <a href="#" className="btn btn-primary">
          {buttonText}
        </a> */}
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default ServiceCard;
