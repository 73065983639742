import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from React Bootstrap
import courses from '../utils/courses.json';
import trainingBanner from '../assets/images/banners/training.jpg';
import '../assets/css/Courses.css'; 

const Courses = () => {
  const [expanded, setExpanded] = useState(Array(courses.length).fill(false));
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const toggleExpand = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const openModal = (course) => {
    setSelectedCourse(course); // Set selected course for modal
    setShowModal(true); // Show modal
  };

  const closeModal = () => {
    setShowModal(false); // Hide modal
    setSelectedCourse(null); // Clear selected course
  };

  return (
    <div className="container py-3">
      <div className="banner-image">
        <img className="img-fluid rounded" alt="Training" src={trainingBanner} />
      </div>

      <div className="row py-3">
        <h2 className="fw-bold text-primary text-uppercase text-center mb-4">Software Training Courses</h2>
        <p>
        "Get Trained, Get Placed! Unlock your career potential with our industry-focused software training courses. Gain the skills you need, and get ready for job placement opportunities. Receive a certificate upon successful completion. Call us for more details!"</p>
        {courses.map((course, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="course-card "> {/* Add h-100 to ensure all cards have equal height */}
              <div className="card-body d-flex flex-column">
                {/* Course Title */}
                <h5 className="card-title bg-primary text-white p-2 rounded">
                  {course.title}
                </h5>

                {/* Brief Description */}
                <p className="card-text bg-light p-2 rounded text-start flex-grow-1">
                  {course.briefDescription}
                </p>  

                {/* Duration */}
                <p className="card-text py-1">
                  <small className="text-muted mr-3">Duration: {course.duration}</small>
                  <button className="btn btn-primary float-end" onClick={() => openModal(course)}>
                    Read More
                  </button>
                </p>            
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for displaying week content */}
      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Course Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCourse && (
            <div>
              <h4>{selectedCourse.title}</h4>
              <p>{selectedCourse.briefDescription}</p>
              <div>
                {selectedCourse.weeks.map((week, index) => (
                  <div key={index} className="mb-3">
                    <h6 className="fw-bold">
                      {week.week ? `Week ${week.week}:` : week.weeks ? `Weeks ${week.weeks}:` : 'No Week Info'} {week.topic}
                    </h6>
                    <p className="text-muted">{week.description}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Courses;
