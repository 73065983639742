import React from 'react';
import ServicesList from './ServicesList';
import servicesBanner from '../assets/images/banners/service.jpg';
const Services = () => {
  return (
    <div className="container py-3">
      <div class="banner-image">
            <img className="img-fluid rounded" alt="About Us" src={servicesBanner} />
      </div>
      <div className="container py-3">
      <h2 className="fw-bold text-primary text-uppercase text-center">Our Services</h2>
      <div className="section-title text-center position-relative pb-3 mx-auto" style={{ maxWidth: '600px' }}>
        <h1 className="mb-0">Custom IT Solutions for Your Successful Business</h1>
      </div>
      <div className="row gx-4 ">
      <ServicesList />
      </div>
      </div>
    </div>
  );
}

export default Services;
