// React component for job application form
import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Job = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    position: '',
    qualification: '',
    experience: '',
    resume: null,
    message: '',
  });

  const positions = ["Software Engineer", "Web Developer", "Digital Marketer", "SEO Specialist"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      await axios.post('https://neinus.com/submit-form.php', data);
      alert('Application submitted successfully!');
      setFormData({
        name: '',
        phone: '',
        email: '',
        position: '',
        qualification: '',
        experience: '',
        resume: null,
        message: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit application. Please try again.');
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary mb-4">Job Application Form</h2>
      <form onSubmit={handleSubmit} className="row g-3">
        {/* Name */}
        <div className="col-md-6">
          <label htmlFor="name" className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        {/* Phone */}
        <div className="col-md-6">
          <label htmlFor="phone" className="form-label">Phone</label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        {/* Email */}
        <div className="col-md-6">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        {/* Position */}
        <div className="col-md-6">
          <label htmlFor="position" className="form-label">Position Applied For</label>
          <select
            className="form-select"
            id="position"
            name="position"
            value={formData.position}
            onChange={handleChange}
            required
          >
            <option value="">Select Position</option>
            {positions.map((pos) => (
              <option key={pos} value={pos}>{pos}</option>
            ))}
          </select>
        </div>

        {/* Qualification */}
        <div className="col-md-6">
          <label htmlFor="qualification" className="form-label">Qualification</label>
          <input
            type="text"
            className="form-control"
            id="qualification"
            name="qualification"
            value={formData.qualification}
            onChange={handleChange}
            required
          />
        </div>

        {/* Experience */}
        <div className="col-md-6">
          <label htmlFor="experience" className="form-label">Years of Experience</label>
          <input
            type="number"
            className="form-control"
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            required
          />
        </div>

        {/* Resume */}
        <div className="col-md-12">
          <label htmlFor="resume" className="form-label">Resume</label>
          <input
            type="file"
            className="form-control"
            id="resume"
            name="resume"
            onChange={handleFileChange}
            required
          />
        </div>

        {/* Message */}
        <div className="col-md-12">
          <label htmlFor="message" className="form-label">Message</label>
          <textarea
            className="form-control"
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
          />
        </div>

        {/* Submit Button */}
        <div className="col-md-12 text-center">
          <button type="submit" className="btn btn-primary w-100">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Job;
