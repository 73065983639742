import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from '../images/NEINUS05.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleAcademyClick = () => {
    navigate('/courses'); // Navigate to the courses page
    setShowMenu(false)
  };

  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <NavLink
              className="navbar-brand"
              to="/"
              style={{
                backgroundImage: `url(${logo})`,
                width: '200px',
                height: '50px',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            ></NavLink>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${showMenu ? 'show' : ''} justify-content-end`}>
              <ul className="navbar-nav">
                {[
                  { path: '/', label: 'Home' },
                  { path: '/about', label: 'About Us' },
                  { path: '/services', label: 'Services' },
                  { path: '/portfolio', label: 'Portfolio' },
                  { path: '/careers', label: 'Careers' },
                  { path: '/contact', label: 'Contact Us' },
                ].map((link) => (
                  <li className="nav-item" key={link.path}>
                    <NavLink
                      className="nav-link"
                      to={link.path}
                      activeClassName="active"
                      onClick={() => setShowMenu(false)}
                    >
                      {link.label}
                    </NavLink>
                  </li>
                ))}
                {/* Add Academy as a button */}
                <li className="nav-item ">
                  <button
                    className="btn btn-primary ml-3"
                    onClick={handleAcademyClick} // Handle the click to navigate to courses
                  aria-label="Go to Neinus Academy courses page"
                  >
                    <strong>Neinus Academy</strong>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;