import React from 'react';
import portfolioBanner from '../assets/images/banners/portfolio.jpg';
import Portfolio from './Portfolio';

import kceImage from '../assets/images/portfolio/kce.png';
import btraditionalsImage from '../assets/images/portfolio/btraditionals.png';
import mmsImage from '../assets/images/portfolio/mms.png';


const clients = [
    {
      title: 'Kaamadhenu College of Education',
      image: kceImage,
      description: {
        Title: 'Kaamadhenu College of Education',
        Description: "Kaamadhenu College of Education is a prominent institution dedicated to providing high-quality teacher training and education. The project focuses on developing a comprehensive online presence for the college to showcase its academic offerings, campus life, faculty expertise, and student achievements. The platform serves as a resource for prospective students, educators, and stakeholders looking to learn more about the college's vision and the impact it has on the future of education.",
        KeyFeatures: [
         "Institution Overview: A detailed introduction to the college, its mission, and core values.",
        "Courses and Programs: Information about various teacher training programs, courses, and certifications offered by the college.",
        "Faculty and Staff: Highlighting the qualifications and experience of the college's experienced faculty members.",
        "Campus Life: Showcasing the vibrant campus environment with photos, student activities, and events.",
        "Student Testimonials: Insights from current students and alumni about their experiences and the value of the education they received.",
        "Events and Workshops: Calendar and information on upcoming educational workshops, seminars, and community outreach programs."
                
          ],
          targetAudience: [
            "Aspiring educators looking to pursue a career in teaching and education.",
           "Parents and guardians seeking information about quality education for their children.",
            "Education professionals looking for opportunities to enhance their skills and qualifications.",
            "Alumni and stakeholders interested in staying connected with the college community.",
          ]
        },
        website: 'https://kcesathy.ac.in',
        
    },
    {
      title: 'B Traditionals',
      image: btraditionalsImage,
      description: {
        Title: 'B Traditionals',
        Description: "This project involves the creation of a user-friendly online store tailored for selling traditional men's and juniors' wear across the USA. The target audience includes men and boys seeking high-quality traditional attire for weddings, festivals, and cultural events, as well as parents shopping for juniors.",
        KeyFeatures: [
            'Responsive Design: Seamless shopping experience across desktops, tablets, and mobile devices.',
            "Product Categories: Easy navigation with filters for men's wear, juniors' wear, accessories, and occasion-based outfits.",
            'Secure Payment Integration: Multiple payment options, including credit cards and digital wallets.',
            'Wishlist and Cart: Save favorite items and manage purchases effortlessly.',
            'Customer Support: Live chat and FAQs for quick assistance.',
            'Fast Shipping: Nationwide delivery with tracking options.',
            'SEO-Optimized Content: Enhanced discoverability for relevant traditional clothing keywords.'                    
          ],
          targetAudience: [
      "Families: Parents buying traditional wear for children for weddings, festivals, and religious events.",
"Young Adults & Professionals: Men seeking stylish yet traditional attire for formal occasions.",
"Online Shoppers: Customers who prefer shopping for traditional wear online.",
"NRI (Non-Resident Indians): People living abroad wanting to stay connected to their roots through traditional clothing.",
       ]       },
        website: 'https://btraditionals.com',
    },
    {
      title: 'Madurai Meenakshi Sculptures',
      image: mmsImage,
      description: {
        Title: 'Madurai Meenakshi Sculptures',
        Description: 'Madurai Meenakshi Sculptures is a tribute to the rich cultural heritage of Tamil Nadu, specifically showcasing the intricate craftsmanship of traditional Indian artisans. The project is centered around creating a digital platform that highlights sculptures inspired by the iconic Madurai Meenakshi Temple, renowned for its architectural grandeur and artistic legacy. This platform caters to art enthusiasts, collectors, and tourists, offering them a virtual gallery to explore and appreciate these timeless creations.',
        KeyFeatures: [
          'Virtual Gallery: A visually appealing digital display of traditional sculptures with high-quality images and detailed descriptions.',
            'Cultural Insights: Informative sections detailing the history and significance of each sculpture and its connection to South Indian culture.',
            "Customization Options: Users can request personalized sculptures or specific designs inspired by the temple's architecture.",
            'Online Purchase: A seamless e-commerce experience to purchase sculptures directly from artisans, promoting local craftsmanship.',
            'Mobile-Optimized Experience: A responsive design ensuring a smooth browsing experience across devices.',
          ],
          targetAudience: [
            "Art enthusiasts and collectors passionate about traditional Indian art.",
             "Travelers and tourists seeking souvenirs or mementos reflecting South Indian heritage.",
             "Academicians and students interested in learning about temple architecture and sculpture.",
             "Buyers looking for authentic handcrafted sculptures for decor or gifting purposes."
          ]
        },
        website: 'https://mmsculptures.com',
    },
  ];
  

const Projects = () => {
  return (
    <div className="container py-3">
      <div class="banner-image">
        <img className="img-fluid rounded" alt="Projects" src={portfolioBanner} />
      </div>

      <div className="container py-3">
      <h2 className="fw-bold text-primary text-uppercase text-center">Our Projects</h2>
      <Portfolio projects={clients} />
      </div>
      </div>
  );
}

export default Projects;

