import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import careersData from '../utils/careers.json';  // import the JSON data
import hiringImage from '../assets/images/joinourteam-02.png';
import hiringBanner from '../assets/images/banners/hiring.jpg';
//import ApplyJob from "./ApplyJob";  // Import ApplyJob component

const Careers = () => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [positions, setPositions] = useState([]);
  //const [showApplyJob, setShowApplyJob] = useState(false); // State to toggle ApplyJob component

  useEffect(() => {
    if (careersData) {
      setPositions(careersData);  // Set positions data from JSON
    } else {
      console.error("Careers data is missing!");
    }
  }, []);

  const handleViewDetails = (id) => {
    setSelectedPosition(selectedPosition === id ? null : id);
  };

  // const toggleApplyJob = () => {
  //   setShowApplyJob(!showApplyJob); // Toggle the ApplyJob component
  // };

  return (
    <div className="container py-3">
      <div className="banner-image">
        <img className="img-fluid rounded" alt="We Hire You" src={hiringBanner} />
      </div>
      
      <section className="career-section p-4 bg-light">
        <div className="center-container">
          <img src={hiringImage} alt="Join Our Team" />
        </div>
        
        <p className="text-start">
          We are happy to announce that Neinus Technologies is currently recruiting new members for our team. 
          We are looking for individuals with a passion for Digital Marketing, Web Development, Trainers, and Sales. 
          If you're interested, send your resume to   &nbsp;
           <a href="mailto:contactus@neinus.com" className="text-primary">contactus@neinus.com</a>
        </p>
        
        <div className="text-start">
          <strong>Location: Sathyamangalam / Remote</strong>
          {/* Apply Now Button */}
          {/* <button className="btn btn-outline-primary ms-3" onClick={toggleApplyJob}>
            Apply Now
          </button> */}
        </div>

        {/* Conditionally render ApplyJob component */}
        {/* {showApplyJob && <ApplyJob />} */}

        <div className="container mt-4">
  {positions.map((position) => (
    <div key={position.id} className="card mb-4 shadow-sm">
      <div
        className="card-body d-flex justify-content-between"
        onClick={() => handleViewDetails(position.id)} // Toggle on card-body click
      >
        <div className="text-start">
          <h5 className="card-title mb-0 text-primary text-start">{position.title}</h5>
          <p className="card-text text-muted text-start">{position.description}</p>
        </div>

        {/* Button: Make it small and float right */}
        <button
          className="btn btn-outline-primary btn-sm ms-auto"
          onClick={(e) => {
            e.stopPropagation(); // Prevent event propagation to card-body
            handleViewDetails(position.id);
          }}
          aria-expanded={selectedPosition === position.id}
          aria-controls={`details-${position.id}`}
        >
          {selectedPosition === position.id ? "Hide Details" : "View Details"}
        </button>
      </div>

      {/* Career Details Section */}
      <div
        id={`details-${position.id}`}
        className={`career-details mt-3 ms-3 ${selectedPosition === position.id ? 'open' : ''}`}
      >
        {selectedPosition === position.id && (
          <>
            <h6 className="mb-2 text-start fw-bold">Responsibilities:</h6>
            <ul className="pl-3 text-start">
              {position.responsibilities.map((responsibility, index) => (
                <li key={index}>{responsibility}</li>
              ))}
            </ul>

            <h6 className="mt-3 mb-2 text-start fw-bold">Qualifications:</h6>
            <ul className="pl-3 text-start">
              {position.qualifications.map((qualification, index) => (
                <li key={index}>{qualification}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  ))}
</div>

      </section>
    </div>
  );
};

export default Careers;
