import React, { useState, useEffect } from 'react';
import '../assets/css/imageCarousel.css'; // Custom styles

// Helper function to load images dynamically from the carousel folder
const importAll = (r) => r.keys().map(r);

const images = importAll(require.context('../assets/images/carousel', false, /\.(jpg|jpeg|png|gif)$/));

// You can create corresponding text for each image dynamically or hard-code them
// const texts = [
//   "Craft Your Digital Future with Cutting-Edge Web Development!",
//   "Empowering Businesses with Innovative Digital Marketing Solutions!",
//   "Climb the Search Engine Ranks with Our SEO Expertise!",
//   "Transform Your Ideas into Reality with Scalable, Custom Web Solutions!",
//   "Achieve Unmatched Online Visibility and Growth with Us!",
//   "Your Partner in Building Stunning Websites and Driving Digital Success!",
// ];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel">
      <button
        className="carousel-button prev"
        onClick={prevSlide}
        aria-label="Previous slide"
      >
        ❮
      </button>
      <div className="carousel-container">
        <div
          className="carousel-slides"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="carousel-slide"
              style={{ backgroundImage: `url(${image})` }}
            >
              {/* <div className="overlay"></div>
              <div className="text-container">
                <h2 className="carousel-caption">{texts[index]}</h2>
              </div>*/}
            </div> 
          ))}
        </div>
      </div>
      <button
        className="carousel-button next"
        onClick={nextSlide}
        aria-label="Next slide"
      >
        ❯
      </button>
    </div>
  );
};

export default ImageCarousel;
