import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
const SocialIcons = () => {
    return (
        <div className="social-icons-container col-lg-2 text-center text-lg-start mb-2 mb-lg-0 " >
            <div className="social-icons">
                <a href="https://www.facebook.com/profile.php?id=61563955530998"  rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faFacebook} />
                </a>
                {/* <a href="/" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTimes} />
                </a> */}
                <a href="https://www.instagram.com/neinus_technologies" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                {/* <a href="/" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faLinkedin} />
                </a> */}
                <a href="https://www.youtube.com/@NeinusTechnologies"  rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faYoutube} />
                </a>
            </div>
        </div>
    );
}

export default SocialIcons;
