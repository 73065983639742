import React, { useState, useEffect } from 'react';
import axios from 'axios';

const JobApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://neinus.com/get_job_applications.php')
        .then((response) => {
          console.log('inside --', response)
        setApplications(response.data); // Update state with fetched data
        setLoading(false); // Set loading to false
      })
        .catch((error) => {
            console.log('inside error --', error.message)
        setError(error.message); // Handle any errors
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Job Applications</h2>
      {applications.length > 0 ? (
        <ul>
          {applications.map((application) => (
            <li key={application.id}>
              <strong>{application.name}</strong> ({application.email}) - {application.position}
            </li>
          ))}
        </ul>
      ) : (
        <p>No job applications found.</p>
      )}
    </div>
  );
};

export default JobApplications;
