import React from 'react';
import ServiceCard from './ServiceCard';
import servicesData from '../utils/servicesData.json'; // Import the services data


const ServicesList = () => {
    return (
        <div className="container my-5">
        <div className="row custom-grid">
        {servicesData.map((service, index) => (
            <div className="col-lg-4 col-md-6 gy-5" key={index}>
              <ServiceCard {...service} />
            </div>
          ))}
        </div>
      </div>
    );
  };
  
export default ServicesList;
