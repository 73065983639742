import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Courses from './components/Courses';
import Careers from './components/Careers';
import Projects from './components/Projects';
import Contact from './components/Contact';
import TopBar from './components/Topbar';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';
import WhatsAppIcon from './components/WhatsAppIcon'; 
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Job from './components/Job';
import JobApplications from './components/JobApplications';

function App() {
  return (
    <Router>
      <TopBar/>
      <Header />
      <div >
       
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/portfolio" element={<Projects />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/careers" element={<Careers />} />
          <Route path="/job" element={<Job />} />
          <Route path="/applications" element={<JobApplications />} />
          
            </Routes>
          </div>
          <WhatsAppIcon phoneNumber="917200864623" /> {/* Neinus WhatsApp number */} 
      <Footer />
    </Router>
  );
}

export default App;
